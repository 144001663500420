export enum Properties {
	IDENTIFIER = "identifier",
	FILE_FORMAT = "fileFormat",
	OUTER_DIMENSIONS = "outerDimensionsMM",
	MANUFACTURING_PROCESS = "manufacturingProcess",
	MATERIAL = "material",
	LICENSOR = "licensor",
	LAST_REQUESTED = "lastRequested",
	LAST_SEEN = "lastSeen",
	OKHV = "okhv",
	TEST = "test",
	FILE_URL = "fileUrl",
	IMAGE = "image",
	SOURCE = "source",
	RELATED_TSDC = "relatedTsDC",
	EXPORT = "export",
	RELEASE = "release",
	ORGANISATION = "organisation",
	DOCS_READINESS_LEVEL = "documentationReadinessLevel",
	VERSION = "version",
	TECH_READINESS_LEVEL = "technologyReadinessLevel",
	CPC_PATENT_CLASS = "cpcPatentClass",
	TIMESTAMP = "timestamp",
	REPO = "repo",
	FUNCTION = "function",
	PERMALINK = "permaURL",
	ORIGINAL_URL = "originalUrl",
	HAS_README = "hasReadme",
	HAS_MANUFACTURING_INSTRUCTIONS = "hasManufacturingInstructions",
	HAS_BOM = "hasBoM",
	HAS_USER_MANUAL = "hasUserManual",
	VERSION_OF = "versionOf",
	HAS_IMAGE = "hasImage",
	CONTRIBUTOR_COUNT = "contributorCount",
	SPDX_LICENSE = "spdxLicense",
	HAS_MANIFEST_FILE = "hasManifestFile",
	HAS_COMPONENT = "hasComponent",
	DOCS_LANGUAGE = "documentationLanguage",
	TYPE = "type",
}
